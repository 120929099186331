.oicon{
    width: 40px;
    height: 30px;
}
.r-icon{
    position: relative;
    margin-left: 2.6vw;
    margin-top: -0.4vh;
    margin-bottom: 2vh;
    display: flex;
}
.f-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.s-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.t-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.hoverfIcon, .hoversIcon, .hovertIcon{
    margin-top: 4px;
    display: none;
    width: max-content;
    font-size: 0.7rem;
}
.f-icon:hover{
    border-radius: 20px;
   background-color: rgba(146, 146, 146, 0.304);
}
.f-icon:hover + .hoverfIcon {
    display: block;
}
.s-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.s-icon:hover + .hoversIcon{
    display: block;
}
.t-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.t-icon:hover + .hovertIcon{
    display: block;
}

.dropdown{
    background-color: rgba(221, 221, 221, 0.933);
    color: rgb(11, 11, 11);
    position: absolute;
    font-size: 0.6rem;
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: max-content;
    top: -12.5vh;    
}
.doptions{
    padding: 8px;
}
.doptions:hover{
background-color: rgba(85, 85, 85, 0.225);
}